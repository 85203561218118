/* mobile breakpoint */
@media (max-width: 768px) {
  .AboutPage {
    width: 100%;
    background-color: #293d5b;
    align-self: center;
    flex-direction: column;
    overflow-y: scroll !important;
  }

  .Container {
    overflow-y: scroll !important;
  }

  .HeaderAndAbout {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    align-self: flex-start;
  }

  .Header {
    padding-top: 13%;
    font-size: 3rem;
    align-self: flex-start;
    text-align: left;
  }

  .AboutText {
    width: 90%;
    font-size: 1.5rem;
    font-family: "Inter", sans-serif;
    text-align: left;
  }

  .ConsultantGrid {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 12%;
    overflow-y: scroll !important;
  }
}

@media (min-width: 769px) {
  .AboutPage {
    width: 100%;
    background-color: #293d5b;
    align-self: center;
  }

  .Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
  }

  .HeaderAndAbout {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    align-self: flex-start;
  }

  .Header {
    font-size: 4rem;
    align-self: flex-start;
    text-align: left;
  }

  .AboutText {
    width: 50%;
    font-size: 1.5rem;
    font-family: "Inter", sans-serif;
    text-align: left;
  }

  .ConsultantGrid {
    display: flex;
    width: 90%;
    gap: 2rem;
    align-self: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
  }
}
