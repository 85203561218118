@media (min-width: 769px) {
  .GuideCard {
    cursor: pointer;
    text-decoration: none;
    background-color: #f2e6c5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    justify-content: center;
    margin: 0%;
    margin-bottom: 2%;
    border-radius: 4px;
    padding: 4rem 0 4rem 0;
  }

  .GuideCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .GuideEmoji {
    font-size: 6rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .GuideTitle {
    margin: 0%;
    text-decoration: none;
    color: #293d5b;
  }
}

/* mobile */
@media (max-width: 768px) {
  .GuideCard {
    cursor: pointer;
    text-decoration: none;
    background-color: #f2e6c5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    justify-content: center;
    margin: 0%;
    border-radius: 4px;
    margin-bottom: 5%;
    padding: 1rem 1rem 1rem 1rem;
  }

  .GuideCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .GuideEmoji {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .GuideTitle {
    margin: 0%;
    font-size: 1.5rem;
    text-decoration: none;
    color: #293d5b;
  }
}
