@media (min-width: 769px) {
  .GuidesPage {
    width: 100vw;
    background-color: #293d5b;
    display: flex;
    flex-direction: column;
  }

  .GuidesHeader {
    align-self: left;
    text-align: left;
    font-size: 4rem;
    margin-left: 5%;
  }

  .GuidesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 5%;
  }

  .GuideCards {
    width: 90%;
    display: flex;
    align-self: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

/* mobile breakpoint */
@media (max-width: 768px) {
  .GuidesPage {
    width: 100vw;
    background-color: #293d5b;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .GuidesHeader {
    padding-top: 10%;
    font-size: 3rem;
    align-self: flex-start;
    text-align: left;
    margin-top: 10%;
    margin-left: 5%;
  }

  .GuideCards {
    width: 100%;
    display: flex;
    align-self: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
