.App {
  text-align: center;
  height: 100%;
  background-color: #293d5b;
  color: #f2e6c5;
  align-items: center;
}

.App-link {
  color: #61dafb;
}
