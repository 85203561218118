@media (min-width: 769px) {
  .HoursPage {
    width: 95vw;
    height: 100vh;
    background-color: #293d5b;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .HoursHeader {
    align-self: center;
    font-size: 4rem;
  }

  .HoursContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 5%;
  }
}

/* mobile breakpoint */
@media (max-width: 768px) {
  .HoursHeader {
    align-self: center;
    font-size: 4rem;
    padding-top: 10%;
  }
}
